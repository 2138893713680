import dynamic from 'next/dynamic'

export type Props = {
  search?: string
}

const NotFoundPage = dynamic<Props>(() =>
  import('./NotFoundPage').then((mod) => mod.default)
)

export default NotFoundPage
