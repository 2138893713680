import createDecorator from 'final-form-focus-custom'

const focus = (input: HTMLInputElement) => {
  input.focus({ preventScroll: true })
  if (input.scrollIntoView) {
    input.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const focusOnError = createDecorator<any>(undefined, undefined, focus)

export default focusOnError
