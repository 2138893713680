import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import QUERY from 'components/pages/TruckPage/checkoutCartQuery'
import Loading from 'components/Loading'
import CartItems from 'components/CartItems'
import { checkoutCartQuery } from 'components/pages/TruckPage/types/checkoutCartQuery'
import ButtonLink from 'components/ui/ButtonLink'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'
import { ArrowRightIcon } from '@heroicons/react/solid'

const Checkout = ({ currentLocationId }: { currentLocationId: number }) => {
  const { albertsons } = useContext(ShiftPageContext)
  const { data, refetch } = useQuery<checkoutCartQuery>(QUERY, {
    fetchPolicy: 'cache-first',
    skip: typeof window === 'undefined'
  })
  const cart = data?.currentCart

  return (
    <div
      className={cn('sticky bottom-0 w-full', {
        'top-5': albertsons,
        'top-20': !albertsons
      })}
    >
      <div className="ml-6">
        <div
          className={cn(
            'rounded-md border border-solid border-gray-200 flex flex-col',
            {
              'bg-albertsons-neutral7': albertsons,
              'bg-gray-50': !albertsons
            }
          )}
          style={{ height: '80vh' }}
        >
          <div
            className="px-4 overflow-auto flex-grow"
            style={{ height: '100%' }}
          >
            <p className="mt-4 text-lg font-medium">My order</p>
            <p aria-live="assertive" className="sr-only" role="alert">
              {`Item added to the cart, the cart currently contains
              ${cart?.price.items.length}
              ${cart?.price.items.length === 1 ? 'item' : 'items'}`}
            </p>

            {!cart && <Loading />}

            {(cart && cart.price.items.length === 0) ||
            currentLocationId !== cart?.location?.id ? (
              <div
                className="italic flex justify-center items-center text-gray-400"
                style={{ height: 'calc(100% - 28px - 1rem)' }}
              >
                <p
                  className={cn({
                    'text-albertsons-neutral1': albertsons,
                    'text-gray-400': !albertsons
                  })}
                >
                  Your cart is empty
                </p>
              </div>
            ) : (
              <div>
                <CartItems
                  cart={cart}
                  cartItemPrices={cart.price.items}
                  refetch={refetch}
                />
              </div>
            )}
          </div>

          {cart &&
            cart.price.items.length > 0 &&
            currentLocationId === cart?.location?.id && (
              <div
                className={cn(
                  'p-4 border-t border-solid border-gray-200 flex justify-between',
                  {
                    'bg-albertsons-neutral7': albertsons,
                    'bg-gray-100': !albertsons
                  }
                )}
              >
                <p>Item subtotal</p>
                <p>{cart.price.subtotal.formatted}</p>
              </div>
            )}
        </div>
        {cart &&
          cart.price.items.length > 0 &&
          currentLocationId === cart?.location?.id && (
            <div className="mt-4">
              <ButtonLink
                color="primary"
                href="/cart"
                variant="contained"
                fullWidth
              >
                <div className="flex flex-row items-end">
                  <p className="mr-2">Continue</p>
                  <ArrowRightIcon />
                </div>
              </ButtonLink>
            </div>
          )}
      </div>
    </div>
  )
}

export default Checkout
