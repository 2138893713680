import React from 'react'

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  }
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1'

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y
  ].join(' ')

  return d
}

type CircleProgressProps = {
  radius: number
  stroke: number
  percentage: number
  text?: string
  fontSize?: number
  textColor?: string
  gradientId: string
  gradientReverseId: string
}

const CircleProgress = ({
  radius: r,
  stroke: s,
  percentage,
  text: t,
  fontSize = 20,
  textColor = 'black',
  gradientId,
  gradientReverseId
}: CircleProgressProps) => {
  const sh = s / 2 // shift
  const startAngle = percentage > 0 ? (360 / 100) * percentage : 1

  const green = '#047857'
  const orange = '#FCD34D'
  const red = '#DC2626'

  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient gradientTransform="rotate(90)" id={gradientId}>
            <stop offset="0%" stopColor={green} />
            <stop
              offset="100%"
              stopColor={percentage === 100 ? green : orange}
            />
          </linearGradient>
          <linearGradient gradientTransform="rotate(90)" id={gradientReverseId}>
            <stop offset="0%" stopColor={percentage === 100 ? green : red} />
            <stop
              offset="100%"
              stopColor={percentage === 100 ? green : orange}
            />
          </linearGradient>
        </defs>
      </svg>
      <svg
        fill="none"
        height={(r + sh) * 2}
        strokeWidth={s}
        width={(r + sh) * 2}
      >
        {t && (
          <g>
            <text
              color={textColor}
              fill={textColor}
              fontSize={fontSize}
              textAnchor="middle"
              x={r - sh / 2 + fontSize / 2}
              y={r + sh / 2 + fontSize / 2}
            >
              {t}
            </text>
          </g>
        )}
        <g>
          <path
            d={`M ${r + sh} ${r * 2 + sh} A ${r} ${r} 90 0 1 ${r + sh} ${sh}`}
            stroke={`url(#${gradientId})`}
          />
          <path
            d={`M ${r + sh} ${sh} A ${r} ${r} 270 0 1 ${r + sh} ${r * 2 + sh}`}
            stroke={`url(#${gradientReverseId})`}
          />
          <path
            d={describeArc(r + sh, r + sh, r, startAngle, 360)}
            stroke="#E5E7EB"
            strokeWidth={s}
          />
        </g>
      </svg>
    </>
  )
}

export default CircleProgress
