import React, { useContext, useState } from 'react'
import { gql } from '@apollo/client'
import { cartItemPricesFragment_items as cartItemType } from 'components/CartItems/types/cartItemPricesFragment'
import { checkoutCartQuery_currentCart as cartType } from 'components/pages/TruckPage/types/checkoutCartQuery'
import EditItemModal from 'components/ItemModal/EditItemModal'
import Image from 'components/Image'
import cn from 'classnames'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import { PencilAltIcon } from '@heroicons/react/outline'

type CartItemArgs = {
  item: cartItemType
  cart: cartType
  index: number
  refetch: () => void
}

type Props = {
  cartItemPrices: cartItemType[]
  cart: cartType
  refetch: () => void
}

export const cartItemPricesFragment = gql`
  fragment cartItemPricesFragment on CartPrice {
    items {
      name
      quantity
      taxIncluded
      total {
        cents
        formatted
      }
    }
  }
`

function CartItem({ item, cart, index, refetch }: CartItemArgs) {
  const { albertsons } = useContext(ShiftPageContext)
  const cartItem = cart.items[index]
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className="flex flex-row justify-between mb-4">
      <div className="w-4/5">
        <div
          aria-label={`Edit cart item ${item.name}`}
          className="flex items-start outline-none-mouse"
          onClick={() => setModalVisible(true)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              setModalVisible(true)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <div className="w-12 h-12 mr-2">
            <Image
              alt="cart item"
              className="rounded-md"
              height={50}
              layout="fixed"
              src={cartItem.locationItem.item.image.thumb}
              width={50}
            />
          </div>
          <div className="mr-2">
            <p>
              <PencilAltIcon
                className={cn(
                  'w-6 h-6 hover:text-orange-700 cursor-pointer inline mr-1',
                  {
                    'text-albertsons-pink-lemonade': albertsons,
                    'text-orange-500': !albertsons
                  }
                )}
              />
              {item.name}
            </p>
            <div className="italic text-sm text-gray-600">
              {cartItem &&
                cartItem.questions.map((question, qi) =>
                  question.answers.length > 0 ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={qi}>
                      <p
                        className={cn({
                          'text-albertsons-neutral1': albertsons
                        })}
                      >
                        {question.locationItemQuestion.text}:{' '}
                        {question.answers.map((a) => a.text).join(', ')}
                      </p>
                    </div>
                  ) : null
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-1/5 justify-end">
        <p className="mb-1 mr-1 whitespace-nowrap">
          {item.quantity > 1 ? `${item.quantity} x` : ''}{' '}
        </p>
        <p>{item.total.formatted}</p>
      </div>

      {modalVisible && cartItem && (
        <EditItemModal
          cartItem={cartItem}
          cartItemIndex={index}
          cartUuid={cart.uuid!}
          closeModal={() => setModalVisible(false)}
          id={cartItem.locationItem.id}
          onUpdate={refetch}
          visible={modalVisible}
        />
      )}
    </div>
  )
}

export default function CartItems({ cartItemPrices, cart, refetch }: Props) {
  return (
    <div className="mb-5 py-4 flex flex-col">
      {cartItemPrices.map((item, index) => (
        <CartItem
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.name}${index}`}
          cart={cart}
          index={index}
          item={item}
          refetch={refetch}
        />
      ))}
    </div>
  )
}
