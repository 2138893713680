import { gql } from '@apollo/client'
import { scheduleLocationFragment } from 'components/LocationCard'
import { locationItemsFragment } from 'components/ActiveShift/MenuItemsList'
import { locationInformationFragment } from 'components/ActiveShift/LocationInformation'

export default gql`
  ${scheduleLocationFragment}
  ${locationItemsFragment}
  ${locationInformationFragment}
  query shiftQuery($id: Int!) {
    location(id: $id) {
      id
      ...scheduleLocationFragment
      ...locationItemsFragment
      ...locationInformationFragment
      lot {
        lotUrl
      }
      truck {
        id
        name
        primaryUpsellTypes {
          id
          name
        }
      }
    }
    upsellTypes {
      id
      name
    }
  }
`
