import React, { useContext, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import MenuItem from 'components/ActiveShift/MenuItem'
import cn from 'classnames'
import {
  shiftQuery_upsellTypes as upsellTypesType,
  shiftQuery_location as locationType
} from 'components/ActiveShift/types/shiftQuery'
import {
  locationItemsFragment_locationItems,
  locationItemsFragment_locationItems as locationItemsType
} from 'components/ActiveShift/types/locationItemsFragment'
import { currentCartQuery_currentCart as currentCartQueryCurrentCart } from 'queries/types/currentCartQuery'
import debounce from 'lodash/debounce'
import smoothVerticalScrolling from 'utils/smoothScrolling'
import { useHeaderShow } from 'components/Header/HeaderShow'
import { itemTagFragment } from 'components/Tag'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

export const locationItemsFragment = gql`
  ${itemTagFragment}
  fragment locationItemsFragment on Location {
    id
    menu {
      id
      foodTypes {
        id
        name
      }
    }
    locationItems(sort: [{ field: auto, direction: asc }]) {
      id
      status
      item {
        id
        name
        active
        description
        tags {
          ...itemTagFragment
        }
        upsellType {
          id
          name
        }
        image {
          small
          medium
          high
          present
        }
        price {
          cents
          formatted
        }
        hasSpecialInstructions
      }
    }
  }
`

type Props = {
  locationItems: locationItemsType[]
  upsellTypes: upsellTypesType[]
  cart: currentCartQueryCurrentCart | null
  location: locationType
  openModal: (id: number) => void
}

const UpsellTypesFilter = ({
  upsellTypes,
  setActiveTab,
  activeTab
}: {
  upsellTypes: upsellTypesType[]
  activeTab: string
  setActiveTab: (id: number) => () => void
}) => {
  const { albertsons } = useContext(ShiftPageContext)
  const { show: showHeader, headerExists } = useHeaderShow()

  return (
    <div
      className={cn(
        'sticky w-full bg-white flex items-center justify-center border-b border-solid border-gray-200 z-10 transition-all duration-500 ease-in-out transform',
        {
          'top-16 lg:top-16': showHeader && headerExists,
          'top-0 lg:top-16': !showHeader && headerExists,
          'top-0': !headerExists
        }
      )}
      role="list"
    >
      {upsellTypes.map((type) => (
        <div key={type.id} role="listitem">
          {/* eslint-disable-next-line react/button-has-type */}
          <button
            aria-label={`${type.name} ${
              activeTab === `section-${type.id}` ? 'selected' : ''
            }`}
            className={cn(
              'mx-1 px-3 py-2 my-2 tracking-wide text-sm cursor-pointer outline-none-mouse transition-all duration-50',
              {
                'bg-orange-100 rounded-md':
                  !albertsons && activeTab === `section-${type.id}`,
                'text-gray-500 rounded-md':
                  !albertsons && activeTab !== `section-${type.id}`,
                'bg-albertsons-secondary2 rounded-btn-albertsons text-albertsons-red-rose':
                  albertsons && activeTab === `section-${type.id}`,
                'text-albertsons-neutral1 rounded-btn-albertsons':
                  albertsons && activeTab !== `section-${type.id}`
              }
            )}
            onClick={setActiveTab(type.id)}
          >
            {type.name}
          </button>
        </div>
      ))}
    </div>
  )
}

const makeSections = ({
  locationItems,
  upsellTypes
}: {
  locationItems: locationItemsType[]
  upsellTypes: upsellTypesType[]
}) => {
  const upsellTypeItems: {
    locationItems: locationItemsType[]
    upsellType: upsellTypesType
  }[] = upsellTypes
    .map((upsellType) => ({
      locationItems: locationItems.filter(
        (locationItem) => locationItem.item.upsellType.id === upsellType.id
      ),
      upsellType
    }))
    .filter((group) => group.locationItems.length > 0)
  return upsellTypeItems
}

const handleScroll = (id: number) => {
  const element = document.getElementById(`section-${id}`)
  if (element) {
    smoothVerticalScrolling({ element, offset: 90 })
  }
}

const MenuItemsList = ({
  locationItems,
  upsellTypes,
  cart,
  location,
  openModal
}: Props) => {
  // eslint-disable-next-line no-param-reassign
  locationItems = locationItems.filter((li) => li.status === 'active')

  const [activeTab, setActiveTab] = useState<string>()

  const { primaryUpsellTypes } = location.truck
  const sortedUpsellTypes = [...primaryUpsellTypes]
  upsellTypes.forEach((t1) => {
    if (!sortedUpsellTypes.find((t2) => t2.id === t1.id)) {
      sortedUpsellTypes.push(t1)
    }
  })
  const sections = makeSections({
    locationItems,
    upsellTypes
  })
  const upsellTypesUniq = sections.map((section) => section.upsellType)

  const setTab = (id: number) => () => {
    setActiveTab(`section-${id}`)
    handleScroll(id)
  }

  const makeSetActiveTab = () => {
    let visibleSection = ''
    let betweenWindowCenterAndTopSection: number

    Array.prototype.slice
      .call(document.getElementsByClassName('food-section'))
      .forEach((section) => {
        const difference =
          section.getBoundingClientRect().height +
          section.getBoundingClientRect().top -
          document.documentElement.clientHeight / 2

        if (
          !betweenWindowCenterAndTopSection ||
          betweenWindowCenterAndTopSection < 0 ||
          (difference > 0 && difference < betweenWindowCenterAndTopSection)
        ) {
          betweenWindowCenterAndTopSection = difference
          visibleSection = section.id
        }
      })

    setActiveTab(visibleSection)
  }

  useEffect(() => {
    const onScroll = debounce(makeSetActiveTab, 100)

    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  const hasImages = (items: locationItemsFragment_locationItems[]): boolean =>
    items.some((locationItem) => locationItem.item.image.present)

  return (
    <div className="rounded-md">
      <UpsellTypesFilter
        activeTab={
          activeTab ||
          `section-${upsellTypesUniq.length > 0 ? upsellTypesUniq[0].id : 0}`
        }
        setActiveTab={(id) => setTab(id)}
        upsellTypes={upsellTypesUniq}
      />

      <div className="px-2" style={{ scrollBehavior: 'smooth' }}>
        {sections.map((section) => (
          <div
            key={`section-${section.upsellType.id}`}
            className="pt-2 food-section"
            id={`section-${section.upsellType.id}`}
          >
            <h2 className="pt-4 px-4 text-lg font-bold">
              {section.upsellType.name}
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4">
              {section.locationItems.map((locationItem) => (
                <MenuItem
                  key={locationItem.id}
                  cart={cart}
                  hasImages={hasImages(section.locationItems)}
                  locationItem={locationItem}
                  openModal={openModal}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MenuItemsList
