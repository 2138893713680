import { gql } from '@apollo/client'

export default gql`
  query checkoutMobileQuery {
    currentCart {
      id
      uuid
      items {
        id
        quantity
      }
      location {
        id
      }
      price {
        subtotal {
          cents
          formatted
        }
      }
    }
  }
`
