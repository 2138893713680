import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import { gql } from '@apollo/client'
import ButtonLink from 'components/ui/ButtonLink'
import pathFromUrl from 'utils/pathFromUrl'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import stripTimeZone from 'utils/stripTimeZone'

import { scheduleLocationFragment as scheduleLocationFragmentType } from './types/scheduleLocationFragment'

export const scheduleLocationFragment = gql`
  fragment scheduleLocationFragment on Location {
    id
    startTime
    endTime
    workStatus
    workStatusHuman
    customerUrl
    allowOrders
    address
    lot {
      id
      name
      address
      fullAddress
      image {
        medium
      }
    }
  }
`

const LocationCard = ({
  location
}: {
  location: scheduleLocationFragmentType
}) => (
  <div key={location.id} className="sm:w-1/3 w-full mb-6">
    <div className="h-full p-3 pt-4 flex flex-col justify-between items-center m-2 rounded-lg bg-white border-solid border border-1 border-gray-50 shadow-md box-border">
      <div className="flex flex-col items-center items-start">
        <LocationMarkerIcon className="text-orange-500 h-7 w-7 text-center sm:mr-0 mr-2" />

        <div>
          <p className="text-lg text-center font-medium sm:mt-2">
            {location.lot.name}
          </p>

          <p className="text-sm break-words mt-3 text-center text-gray-500">
            {location.lot.fullAddress}
          </p>
          <p className="mt-2 text-sm font-medium text-center">
            {`${format(parseISO(stripTimeZone(location.startTime)), 'M/d/y')}, 
          ${format(parseISO(stripTimeZone(location.startTime)), 'h:mm aaaa')} -
          ${format(parseISO(stripTimeZone(location.endTime)), 'h:mm aaaa')}`}
          </p>
        </div>
      </div>
      <div>
        {location.allowOrders && (
          <div className="mt-4">
            <ButtonLink
              color="primary"
              href={pathFromUrl(location.customerUrl)}
              prefetch={false}
              size="small"
              variant={
                location.workStatus === 'pre_order' ? 'contained' : 'outline'
              }
            >
              {location.workStatus === 'pre_order' ? 'Order now' : 'Browse'}
            </ButtonLink>
          </div>
        )}
        {!location.allowOrders && (
          <div className="bg-gray-200 rounded-full mt-4 px-2 py-1 text-xs">
            Offline Event
          </div>
        )}
      </div>
    </div>
  </div>
)

export default LocationCard
