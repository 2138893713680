import { gql } from '@apollo/client'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import CircleProgress from 'components/ui/CircleProgress'
import stripTimeZone from 'utils/stripTimeZone'

import { orderProgressFragment as orderProgressFragmentType } from './types/orderProgressFragment'

export const orderProgressFragment = gql`
  fragment orderProgressFragment on Location {
    id
    showAmountLeftToOrder
    amountLeftToOrderPercentage
    deadlineAt
  }
`
type Props = {
  location: orderProgressFragmentType
  textColor: string
  id: string
}

const OrdersProgress = ({
  location: { deadlineAt, amountLeftToOrderPercentage },
  textColor,
  id
}: Props) => {
  return (
    <div className="overflow-hidden flex items-start">
      <div className="w-24 md:scale-100 scale-75">
        <CircleProgress
          gradientId={`circular-progress-gradient-${id}`}
          gradientReverseId={`circular-progress-gradient-reverse-${id}`}
          percentage={100 - amountLeftToOrderPercentage!}
          radius={40}
          stroke={12}
          text={`${Math.round(100 - amountLeftToOrderPercentage!)}%`}
          textColor={textColor}
        />
      </div>

      <p className="text-sm md:pl-3 max-w-xs mt-6 md:mt-1 md:leading-6">
        {amountLeftToOrderPercentage === 0
          ? 'Congratulations! You have secured the truck for the shift!'
          : `${amountLeftToOrderPercentage}% left to order to meet the minimum before ${format(
              parseISO(stripTimeZone(deadlineAt)),
              'Pp'
            )}`}
      </p>
    </div>
  )
}

export default OrdersProgress
