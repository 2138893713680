import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import PageError from 'components/pages/PageError'
import AddItemModal from 'components/ItemModal/AddItemModal'
import useCart from 'queries/useCart'
import MenuItemsList from 'components/ActiveShift/MenuItemsList'
import {
  shiftQuery,
  shiftQueryVariables
} from 'components/ActiveShift/types/shiftQuery'
import LocationInformation from 'components/ActiveShift/LocationInformation'
import Loading from 'components/Loading'
import Checkout from 'components/pages/TruckPage/Checkout'
import CheckoutMobileButton from 'components/ChekoutMobileButton'
import OrdersProgress from 'components/pages/ShiftPage/OrdersProgress'
import { LocationWorkStatus } from 'types/graphql-global-types.d'
import LocationNotice, { hasNotice } from 'components/LocationNotice'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'

import SHIFT_QUERY from './shiftQuery'

type Props = {
  locationId: number
  hideOrdersProgress: boolean
  itemSource: string
  hideLocationInformation: boolean
}

const ActiveShift = ({
  locationId,
  hideOrdersProgress,
  itemSource,
  hideLocationInformation
}: Props) => {
  const { albertsons } = useContext(ShiftPageContext)
  const { cart } = useCart({ skip: typeof window === 'undefined' })

  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [modalItemId, setModalItemId] = React.useState<number | null>(null)

  const closeModal = () => {
    setModalIsOpen(false)
  }
  const openModal = (itemId: number) => {
    setModalItemId(itemId)
    setModalIsOpen(true)
  }

  const { data, error } = useQuery<shiftQuery, shiftQueryVariables>(
    SHIFT_QUERY,
    {
      variables: {
        id: locationId
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  if (!data?.location) return <Loading className="w-full h-72" />
  if (error) return <PageError>{error.message}</PageError>

  const location = data.location!

  const canOrder =
    location.workStatus !== LocationWorkStatus.work_not_started &&
    location.workStatus !== LocationWorkStatus.work_paused &&
    location.allowOrders

  return (
    <div className="w-full min-w-full flex md:mt-2 md:mb-6 xl:mt-4 sm:px-6">
      <div
        className={cn(
          'md:rounded-md md:border md:border-solid bg-gradient-to-t xl:w-3/4 lg:w-2/3',
          {
            'border-albertsons-neutral6 from-albertsons-neutral7 via-albertsons-neutral7': albertsons,
            'border-gray-200 from-gray-100 via-gray-100': !albertsons
          }
        )}
      >
        <div className="flex flex-col">
          <div className="pb-12 relative w-full">
            {!hideLocationInformation && (
              <div className="border-b border-solid border-gray-200 pt-2">
                <LocationInformation location={location} />
              </div>
            )}
            <div className="md:w-1/2 lg:hidden block">
              {location.showAmountLeftToOrder && !hideOrdersProgress && (
                <div className="border-b border-solid border-gray-200">
                  <OrdersProgress
                    id="active-shift-section"
                    location={location}
                    textColor="black"
                  />
                </div>
              )}
            </div>

            <MenuItemsList
              cart={cart}
              location={location}
              locationItems={location.locationItems}
              openModal={openModal}
              upsellTypes={data.upsellTypes}
            />
            {cart && (
              <AddItemModal
                canOrder={canOrder}
                closeModal={closeModal}
                id={modalItemId!}
                locationId={location.id}
                modalIsOpen={modalIsOpen}
                notice={
                  <>
                    {hasNotice(location) && (
                      <div className="m-3">
                        <LocationNotice location={location} />
                      </div>
                    )}
                  </>
                }
                source={itemSource}
                upsell={false}
              />
            )}
          </div>
        </div>
      </div>
      <div className="xl:w-1/4 lg:w-1/3 relative hidden md:block">
        <Checkout currentLocationId={location.id} />
      </div>
      <div className="md:hidden">
        <CheckoutMobileButton currentLocationId={location.id} />
      </div>
    </div>
  )
}

export default ActiveShift
