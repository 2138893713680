import { gql } from '@apollo/client'
import { cartPricesFragment } from 'components/CartPrices'
import { cartItemPricesFragment } from 'components/CartItems'
import { editItemModalCartItemFragment } from 'components/ItemModal/EditItemModal'

export default gql`
  ${cartPricesFragment}
  ${cartItemPricesFragment}
  ${editItemModalCartItemFragment}
  query checkoutCartQuery {
    currentCart {
      id
      uuid
      location {
        id
      }
      items {
        ...editItemModalCartItemFragment
      }
      price {
        ...cartPricesFragment
        ...cartItemPricesFragment
        subtotal {
          cents
          formatted
        }
      }
    }
  }
`
