import ButtonLink from 'components/ui/ButtonLink'
import { useQuery } from '@apollo/client'
import { checkoutMobileQuery } from 'components/ChekoutMobileButton/types/checkoutMobileQuery'

import QUERY from './query'

const CheckoutMobileButton = ({
  currentLocationId
}: {
  currentLocationId: number
}) => {
  const { data } = useQuery<checkoutMobileQuery>(QUERY, {
    fetchPolicy: 'cache-first',
    skip: typeof window === 'undefined'
  })

  const cart = data?.currentCart

  if (!cart) return null
  if (currentLocationId !== cart?.location?.id) return null
  if (cart.items.length === 0) return null

  const itemCount = cart.items.reduce((acc, i) => i.quantity + acc, 0)
  return (
    <div className="fixed md:hidden bottom-0 left-0 right-0 z-20 bg-white border-t border-gray-200 border-solid pt-2 px-4 pb-6">
      <ButtonLink color="primary" href="/cart" variant="contained" fullWidth>
        <div className="flex justify-between w-full">
          <p>{`${itemCount} ${itemCount === 1 ? 'item' : 'items'}`}</p>
          <p>Checkout</p>
          <p>{cart.price.subtotal.formatted}</p>
        </div>
      </ButtonLink>
    </div>
  )
}

export default CheckoutMobileButton
