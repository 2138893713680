import { useEffect } from 'react'
import { useRouter } from 'next/router'
import apiUrl from 'utils/apiUrl'

export default function useReferrals() {
  const router = useRouter()
  const { r: referral, partnership } = router.query ?? {}
  useEffect(() => {
    if (!referral?.length && !partnership?.length) return

    window.fetch(`${apiUrl()}/referral`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        click_url: window.location.origin + window.location.pathname,
        referer: document.referrer,
        ...router.query
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral, partnership])
}
