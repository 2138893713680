import { gql } from '@apollo/client'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import Link from 'next/link'
import { LocationWorkStatus } from 'types/graphql-global-types.d'
import { orderProgressFragment } from 'components/pages/ShiftPage/OrdersProgress'
import stripTimeZone from 'utils/stripTimeZone'
import { ClockIcon, LocationMarkerIcon } from '@heroicons/react/outline'

import { locationInformationFragment as locationInformationFragmentType } from './types/locationInformationFragment'

export const locationInformationFragment = gql`
  ${orderProgressFragment}
  fragment locationInformationFragment on Location {
    id
    address
    allowOrders
    startTime
    endTime
    workStatus
    workStatusHuman
    lot {
      id
      name
      address
      fullAddress
      appNotice
      lotUrl
    }
    ...orderProgressFragment
  }
`
type Props = {
  location: locationInformationFragmentType
}

const locationTitle = ({
  workStatus,
  startTime
}: {
  workStatus: string
  // eslint-disable-next-line
  startTime: any
}) => {
  if (workStatus === LocationWorkStatus.work_not_started) {
    return 'Orders will open soon'
  }
  if (workStatus === LocationWorkStatus.work_started) {
    return 'Orders are open - Order Now'
  }
  if (workStatus === LocationWorkStatus.pre_order) {
    return 'Orders are open - Pre-order Now'
  }
  if (workStatus === LocationWorkStatus.work_paused) {
    return 'Truck paused taking orders - check back later'
  }
  if (workStatus === LocationWorkStatus.work_ended && startTime > Date.now()) {
    return 'The shift was cancelled'
  }
  if (workStatus === LocationWorkStatus.work_ended) {
    return 'The shift has ended'
  }
  return null
}

const LocationInformation = ({ location }: Props) => (
  <div className="mx-4">
    <p className="mb-2 font-medium text-gray-800 text-md text-center">
      {locationTitle({
        workStatus: location.workStatus,
        startTime: location.startTime
      })}
    </p>
    <div className="flex flex-col">
      <div className="flex mt-2 flex-col md:flex-row">
        <div className="md:w-1/2">
          <div className="w-full flex flex-row items-start first:sm:border-b-0 first:border-b  first:border-solid first:border-gray-200 mr-6 mb-2">
            <LocationMarkerIcon className="text-orange-500 sm:h-8 h-6 sm:w-8 w-6 mt-1 sm:mt-0" />
            <div className="mt-1 mx-2">
              <div className="font-medium text-sm sm:text-base ">
                <Link href={location.lot.lotUrl!} prefetch={false}>
                  {location.lot.name}
                </Link>
              </div>
              <p className=" text-gray-600 break-words sm:mt-2 mt-1 text-sm sm:text-md underline">
                <a
                  href={`https://www.google.com/maps/search/${encodeURIComponent(
                    location.lot.fullAddress!
                  )}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {location.lot.fullAddress}
                </a>
              </p>
            </div>
          </div>

          <div className="w-full flex flex-row items-start first:sm:border-b-0 first:border-b first:border-solid first:border-gray-200 mr-6 mb-2">
            <ClockIcon className="text-orange-500 sm:h-8 h-6 sm:w-8 w-6 mt-1 sm:mt-0" />
            <div className="mt-1 mx-2">
              <p className="text-sm font-medium sm:text-base">
                {` 
          ${format(parseISO(stripTimeZone(location.startTime)), 'h:mm aaaa')} -
          ${format(
            parseISO(stripTimeZone(location.endTime)),
            'h:mm aaaa'
          )}, ${format(parseISO(stripTimeZone(location.startTime)), 'M/d/y')}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LocationInformation
