import React, { useContext } from 'react'
import { currentCartQuery_currentCart as currentCartQueryCurrentCart } from 'queries/types/currentCartQuery'
import { locationItemsFragment_locationItems as locationItemsType } from 'components/ActiveShift/types/locationItemsFragment'
import cn from 'classnames'
import Image from 'components/Image'
import Tag from 'components/Tag'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import { LocationItemMenuFragment } from 'components/ActiveShift/types/LocationItemFragment'

type Props = {
  locationItem: locationItemsType | LocationItemMenuFragment
  openModal: (id: number) => void
  cart: currentCartQueryCurrentCart | null
  hasImages: boolean
}

const MenuItem = ({ locationItem, openModal, cart, hasImages }: Props) => {
  const { albertsons } = useContext(ShiftPageContext)

  const cartItems = cart?.items.filter(
    (cartItem) => cartItem.locationItem.id === locationItem.id
  )

  const isActive = locationItem.status === 'active'

  return (
    <div
      className="lg:my-4 my-2 outline-none-mouse"
      onClick={() => isActive && openModal(locationItem.id)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          return isActive && openModal(locationItem.id)
        }
        return null
      }}
      role="button"
      tabIndex={0}
    >
      <div className="group shadow-md hover:shadow-xl transition-all duration-200 rounded-lg box-border mx-2 xl:mx-4 p-3 bg-white overflow-hidden h-full flex flex-col justify-between cursor-pointer">
        <div
          className={cn({
            'py-2': !hasImages
          })}
        >
          <div className="-mt-3 -mx-3 relative">
            <div className="w-full relative">
              {hasImages && (
                <Image
                  alt={locationItem.item.name}
                  height={600}
                  layout="responsive"
                  sizes="(max-width: 768px) 50vw,25vw"
                  src={locationItem.item.image.high}
                  width={600}
                />
              )}
              <div className="absolute top-1 right-1 flex">
                {locationItem.item.tags
                  .filter((tag) => tag.imageSvgUrl && tag.highlighted)
                  .map((tag) => (
                    <Tag key={tag.id} tag={tag} hideName />
                  ))}
              </div>
            </div>
          </div>
          <p className="font-base text-center mt-2">{locationItem.item.name}</p>
          {!hasImages && (
            <div className="mt-4 text-sm font-light text-gray-600 text-justify">
              {locationItem.item.description}
            </div>
          )}
        </div>

        <div
          className={cn(
            'shadow mt-2 text-center p-1 rounded-lg w-full relative transition-all duration-500',
            {
              'hover:bg-albertsons-neutral6': isActive && albertsons,
              'hover:bg-gray-200': isActive && !albertsons,
              'bg-albertsons-neutral7': albertsons,
              'bg-gray-100': !albertsons
            }
          )}
        >
          {isActive && (
            <>
              <div
                className={cn('text-center text-xs', {
                  'text-albertsons-neutral1': albertsons,
                  'text-gray-400': !albertsons
                })}
              >
                add
              </div>
              <div className="text-center font-semibold text-sm">
                {locationItem.item.price.formatted}
              </div>
              {cartItems && cartItems.length > 0 && (
                <div
                  className={cn(
                    'text-white rounded-full px-2 py-0.5 text-sm font-semibold absolute right-2 top-1/2 -translate-y-1/2',
                    {
                      'bg-albertsons-pink-lemonade': albertsons,
                      'bg-orange-600': !albertsons
                    }
                  )}
                >
                  {cartItems.reduce((prev, ci) => prev + ci.quantity, 0)}
                </div>
              )}
            </>
          )}

          {!isActive && (
            <p
              className={cn('text-sm', {
                'text-albertsons-neutral1': albertsons,
                'text-gray-400': !albertsons
              })}
            >
              Out of stock
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default MenuItem
