import React from 'react'
import PageLayout from 'components/pages/PageLayout'

import PageError from '../PageError'

export type Props = {
  message: JSX.Element | string
  children?: JSX.Element | string
}

const ErrorPage = ({ message, children }: Props) => (
  <PageLayout staticPage>
    <div className="min-h-72">
      {message && (
        <PageError style={{ minHeight: '18rem' }}>{message}</PageError>
      )}
      {children}
    </div>
  </PageLayout>
)

export default ErrorPage
